import { render, staticRenderFns } from "./Detail.vue?vue&type=template&id=c37c234e&scoped=true"
import script from "./Detail.vue?vue&type=script&lang=js"
export * from "./Detail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c37c234e",
  null
  
)

export default component.exports