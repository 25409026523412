<style lang="less" scoped>
</style>
<template>
  <div>
    <div class="workplatform-title">基本信息</div>
    <Row>
      <i-col span="8"><span class="title">编号 </span>{{contractInfo.contractCode}}</i-col>
      <i-col span="8"><span class="title">客户 </span>{{contractInfo.customerName}}</i-col>
      <i-col span="8"><span class="title">品牌 </span>{{contractInfo.name}}</i-col>
      <i-col span="8"><span class="title">发布类型 </span>{{contractInfo.contractCategoryName}}</i-col>
      <i-col span="8"><span class="title">档期时间 </span>{{contractInfo.contractStartDate}} 至 {{contractInfo.contractEndDate}}</i-col>
      <i-col span="8"><span class="title">创建者 </span>{{contractInfo.userName}}</i-col>
      <i-col span="8"><span class="title">创建时间 </span>{{contractInfo.createTime}}</i-col>
    </Row>

    <div class="workplatform-title">当期费用类别</div>
     <Tabs
        @on-click="changeType"
        v-model="selectedFeeType"
      >
        <TabPane
          v-for="(item,index) in feeTypeList"
          :key="index"
          :label="item.feeTypeName"
          :name="item.feeType.toString()"
        >
          <Table
             stripe :data="feeItemList"
            :columns="feeItemColumns"
          ></Table>
        </TabPane>
      </Tabs>
  </div>
</template>

<script>
// import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'
// import { GetCurrentSchedule } from '@/utils/dateFormat'
import { getContractDetail } from '@/api/scp/contractbill'
// import { contractBillDetail } from '@/api/statement/agentbill'
import { listContractExecuteDetail } from '@/api/dw/verify'
// import { getContractExecutedDetail } from '@/api/statement/agentbill'
import { addFeeAdjustItem, changeFeeAdjustItem } from '@/api/statement/agentbilladjust'
import { formatReconcilliationStatus } from '@/utils/tagStatus'
export default {
  data () {
    return {

      contractInfo: {},
      feeList: [],
      feeTypeList: [],
      feeItemList: [],
      selectedFeeType: null,
      executeTypeArray: new Map([
        [1, '系统生成'],
        [2, '系统调整'],
        [3, '人工调整']
      ]),
      feeItemColumns: [
        {
          title: '发生时间',
          align: 'center',
          render: (h, param) => {
            // return h('span', GetCurrentSchedule(param.row.startDate, param.row.endDate))
            return h('span', `${param.row.startDate} 至 ${param.row.endDate}`)
          }
        },
        {
          title: '记录方式',
          align: 'center',
          key: 'executeType',
          render: (h, param) => {
            // return h('span', toMoney(param.row.signAmount))
            return h('span', this.executeTypeArray.get(param.row.executeType))
          }
        },
        {
          title: '合同执行额',
          align: 'center',
          key: 'amount',
          render: (h, param) => {
            return h('div', [
              h('span', {
                style: { marginRight: '5px' }
              }, toMoney(param.row.amount))
            ])
          }
        },
        {
          title: '备注',
          align: 'center',
          key: 'executeRemark'
        },
        {
          title: '状态',
          key: 'executeStatus',
          width: 120,
          render: (h, data) => {
            return formatReconcilliationStatus(
              h,
              data.row.executeStatus + '',
              data.row.executeStatus === 1 ? '已对账' : '未对账'
            )
          }
        }
      ],
      modalAdd: false,
      feeTypeDisabled: false,
      optionType: 1, // 1:新增，2:修改
      formData: {
        bid: null,
        companyId: null,
        feeType: null,
        adjustAmount: null,
        mark: ''

      },
      formDataValidate: {
        feeType: [
          { required: true, type: 'number', message: '请选择费用类型', trigger: 'change' }
        ],
        adjustAmount: [
          { required: true, type: 'number', message: '请填写调整项金额', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getContractInfo()
    this.getBillDetail()
  },
  computed: {
    contractId () {
      return this.$store.state.reconciliationNew.contractId
    }
  },
  methods: {
    getContractInfo () {
      getContractDetail({ bid: this.contractId }).then(res => {
        if (res && !res.errcode) {
          this.contractInfo = res
        }
      })
    },
    getBillDetail () {
      const query = {
        subjectId: parseInt(this.contractId)
        // startDate: this.startDate,
        // endDate: this.endDate
      }
      listContractExecuteDetail(query).then(res => {
        if (res && !res.errcode) {
          this.feeList = res
          this.feeTypeList = res.map(x => {
            return {
              feeType: x.feeType,
              feeTypeName: x.feeTypeName
            }
          })
          this.selectedFeeType = this.feeTypeList[0].feeType.toString()
          this.feeItemList = res[0].itemList
        } else {
          this.feeList = []
        }
      })
    },
    changeType (name) {
      const feeType = parseInt(name)
      this.feeItemList = this.feeList.find(x => x.feeType === feeType).itemList
    },
    /**
     * 新增编辑中更改费用类型
     */
    handleChangeFeeType () {
      if (this.optionType === 1 && this.formData.feeType) {
        const itemList = this.feeList.find(x => x.feeType === this.formData.feeType).itemList
        if (itemList.some(x => x.executeType === 3)) {
          this.$Notice.error({ title: '无法操作', desc: '当前所选费用类型已存在人工调整项，若需调整，请前往编辑' })
          return false
        }
      }
    },
    handleAdd () {
      this.optionType = 1
      this.feeTypeDisabled = false
      this.formData.feeType = null
      this.formData.adjustAmount = null
      this.formData.mark = ''
      this.modalAdd = true
    },
    /**
     * 保存人工调整
     */
    handleSave () {
      this.$refs.adjustment.validate((valid) => {
        this.formData.bid = this.contractInfo.bid
        this.formData.companyId = this.contractInfo.companyId
        this.formData.publisherId = this.publisherId
        this.formData.startDate = this.startDate
        this.formData.endDate = this.endDate
        this.formData.versionId = this.versionId

        if (valid) {
          this.modalAdd = false
          if (this.optionType === 1) { // 新增
            addFeeAdjustItem(this.formData).then(res => {
              if (res && res.errcode === 0) {
                this.getBillDetail()
                this.$Notice.success({ desc: '操作成功' })
              } else {
                this.$Notice.error({ title: '操作失败', desc: res.errmsg })
              }
            })
          } else {
            changeFeeAdjustItem(this.formData).then(res => {
              if (res && res.errcode === 0) {
                this.getBillDetail()
                this.$Notice.success({ desc: '操作成功' })
              } else {
                this.$Notice.error({ title: '操作失败', desc: res.errmsg })
              }
            })
          }
        }
      })
    }
  }
}
</script>
